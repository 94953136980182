.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-quill .ql-editor {
  height: 250px;
  font-size: 1.2rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  @media (min-width: 768px) {
    font-size: 1.5rem;
    
  }

  @media (max-width: 767px) {
    font-size: 1rem;
    
  }
}

.loading04 {
  span {
    animation: loading04 1s infinite;
    @for $i from 1 through 10 {
      &:nth-child(#{$i+1}) {
        animation-delay: #{$i*.1}s;
      }
    }
  }
}
@keyframes loading04 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}